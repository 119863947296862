import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('../views/init.vue'),
        meta: {
            title: "实验室小助手",
        },
    },
    {
        path: '/Login',
        component: () => import('../views/Login/Login.vue'),
        meta: {
            title: "登录",
        },
    },
    {
        path: '/Home',
        component: () => import('../views/Home/index'),
        meta: {
            title: "实验室小助手",
        },
    },
    {
        path: '/AiTest',
        component: () => import('../views/Home/AiTest'),
        meta: {
            title: "智能客服",
        },
    },
    {
        path: '/Equipment',
        component: () => import('../views/Equipment/index'),
        meta: {
            title: "设备维保",
        },
    },
    {
        path: '/SampleCamera',
        component: () => import('../views/SampleCamera.vue'),
        meta: {
            title: "扫码",
        },
    },
    {
        path: '/Code128',
        component: () => import('../views/Code/index.vue'),
        meta: {
            title: "条形码",
        },
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;

    next();
        // next()
})

export default router
