<template>
  <div id="app" ref="app" :style="width">
    <router-view/>
  </div>
</template>

<script>

export default {
  watch: {
    '$store.state.userInfo'() {
      // this.init()
    }
  },
  data() {
    return {
      width: '',
      title: '实验室小助手',
    }
  },
  created() {
    const {clientWidth} = document.documentElement

    setTimeout(() => {
      if (clientWidth >= 500) {
        this.width = 'width:375px;margin: 0 auto;'
        // this.$refs.app.style.width='375px'
      }
    }, 300)

    // 页面每次刷新加载时候都会去读取localStorage里面的vuex状态
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(localStorage.getItem("store")) //这里存的是可能经过mutions处理过的state值，是最新的,所以放在最后
          )
      )
    }
    // 在页面刷新之前把vuex中的信息存到sessionStoreage
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });

    this.$root.$on('init', () => {
      this.init()
    })
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    localStorage.setItem("store", JSON.stringify(this.$store.state));
  },
  methods: {
    init() {
      // this.wxShare()
    },
    async getKeyParams() {
      // let url = window.location.href;
    },
    offsetHeight() {
      return this.$refs.app.offsetHeight
    },

  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}


div::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

div::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #89ddff;
}

div::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #ededed;
}


@font-face {
  font-family: 'PuHuiTi';
  src: url('https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2024-4-29/AlibabaPuHuiTi-3-55-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}


</style>
